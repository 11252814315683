import { registerImageScaler } from "@crown/ui/Image/image-scalers";
import type { ImageScaler } from "@crown/ui/Image/sizing";
import md5 from "md5";
import {
  getImgIxBaseUrl,
  getImgIxToken,
  getStoryblokImageUrl,
} from "./utils/env/env";
export { getSizing } from "@crown/ui/Image/sizing";

const imgIxBaseUrl = getImgIxBaseUrl();
const imgIxToken = getImgIxToken();

const expandStoryblokImageUrl = (url: string) => {
  if (url.startsWith("/")) {
    return getStoryblokImageUrl() + url;
  } else {
    return url;
  }
};

const productImageAspect = 4 / 3;

export const imgIxImageScaler: ImageScaler = ({
  src,
  width,
  height,
  format,
  rawOpts,
}) => {
  if (src.includes("imgix") || src.startsWith("data:")) {
    const isFitInRawOpts = rawOpts && rawOpts?.indexOf("fit=") > -1;

    return src.startsWith("data:")
      ? src
      : `${src.replace(/ /g, "%20")}?auto=compress%2Cformat&w=${width}${isFitInRawOpts ? "" : "&fit=clamp"
      }${height ? `&h=${height}` : ""}&q=${format == "lowres" ? 5 : 50}${rawOpts ? "&" + rawOpts : ""
      }`;
  } else {
    return src;
  }
};

export const centraImgIxImageScaler: ImageScaler = ({
  src,
  width,
  height,
  format,
  rawOpts,
}) => {
  if (src.includes(".centra")) {
    const query = `?auto=compress%2Cformat&w=${width}&fit=clamp${height ? `&h=${height}` : ""
      }&q=${format == "lowres" ? 5 : 100}${rawOpts ? "&" + rawOpts : ""}`;
    const encodedSrc = encodeURIComponent(src);
    const signature = md5(`${imgIxToken}/${encodedSrc}${query}`);

    return `${imgIxBaseUrl}${encodedSrc}${query}&s=${signature}`;
  } else {
    return src;
  }
};

export const storyblokImageScaler: ImageScaler = ({
  src,
  width,
  height,
  format,
}) => {
  if (src.includes("storyblok.com") || src.startsWith("/")) {
    if (src.startsWith("/")) {
      src = expandStoryblokImageUrl(src);
    }

    return src.startsWith("https://")
      ? `${src.replace(/ /g, "%20")}/m/${width}x${height ?? 0
      }/filters:quality(${format == "lowres" ? 5 : 75})`
      : src;
  } else {
    return src;
  }
};

/* Local image that can be used as fallback when images are missing */
export const placeholderImageSrc = "/static/placeholder.png";

export const defaultProductSizing = {
  aspect: productImageAspect,
};

export const defaultLogoSizing = {
  aspect: 1,
  rawOpts:
    // there is some sort of bug in imgix that breaks transparent images that are scaled with the
    // default parameters, so for logos - that are likely to be transparent - work around it with
    // these settings.
    "fit=fill&fill=solid&fill-color=0FFF",
};

export function registerImageScalers() {
  registerImageScaler(imgIxImageScaler);
  registerImageScaler(centraImgIxImageScaler);
  registerImageScaler(storyblokImageScaler);
}
